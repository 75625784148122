<template>
  <div>
    <a-input v-model="selectNames" disabled placeholder="请选择物品" allowClear>
      <a-button type="link" size="small" icon="search" @click="show()" slot="addonAfter"></a-button>
    </a-input>
    <a-modal :visible="visible" width="60%" title="选择物品" centered @cancel="visible = false" @ok="confirm()">
      <a-layout>
        <div class="content-header">
          <a-affix :offset-top="0" style="width: 100%;">
            <a-row :gutter="15">
              <a-col span="6">
                <a-input v-model="param.keyword" allowClear search @pressEnter="getList" @on-clear="getList"
                         placeholder="关键词查询" @change="getList"></a-input>
              </a-col>
              <a-col span="6" v-if="getOrganId() == 0">
                <a-select v-model="param.organId" style="width:100%" @change="e => {
                  this.param.depId = '';
                  organChange();
                }
                  " placeholder="根据单位筛选">
                  <a-select-option value="">所有单位</a-select-option>
                  <a-select-option :value="organ.organId" v-for="(organ, index) in organList" :key="index">{{ organ.name
                  }}</a-select-option>
                </a-select>
              </a-col>
            </a-row>
          </a-affix>
        </div>
      </a-layout>

      <a-layout>
        <a-layout-content>
          <a-table :row-selection="rowSelection" :scroll="{ x: '100%' }" :pagination="false" :indentSize="25"
                   :style="{ background: '#FFF' }" size="small" row-key="productId" border :columns="keys" ref="list"
                   :loading="loading" :data-source="data.records">
            <template slot-scope="row" slot="organId">
              <div>{{ organList.find(item => item.organId == row.organId).name }}</div>
            </template>
            <!--操作-->
            <template slot-scope="row" slot="action">
              <a-button icon="check" type="link" shape="circle" size="small" @click="selectRow(row)"></a-button>
            </template>
          </a-table>
        </a-layout-content>
      </a-layout>
      <a-row>
        <a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
          <a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext" @showSizeChange="setLimit"
                        :current="data.current" />
        </a-col>
      </a-row>
      <a-back-top />
    </a-modal>
  </div>
</template>

<script>
import utils from '@/common/utils';
import request from '@/config/request';
import apiUtil from '@/common/apiUtil';
export default {
  data() {
    return {
      visible: false,
      loading: false,
      data: [],
      organList: apiUtil.getOrganList(),
      selectedIds: [],
      selectedList: [],
      selectNames: '', //已选择的
      param: {
        page: 1,
        limit: 20,
        depIds: '',
        keyword: '',
        organId: apiUtil.getOrganId(),
        status: 1
      },
      keys: []
    };
  },
  methods: {
    ...apiUtil,
    // 选择某一行
    selectRow(row) {
      this.visible = false;
      this.selectedIds = [row.productId];
      this.selectedList[this.param.page] = [row];
      this.selectNames = row.productName;
      this.productId = row.productId;

      this.$emit('confirm', [row], [row.productId]);
      this.$emit('input', row.productId);
    },
    // 多选确认
    confirm() {
      let result = [];
      this.selectedList.forEach(r => {
        result = result.concat(r);
      });
      let names = [];
      result.forEach(item => {
        names.push(item.productName);
      });
      this.selectNames = names.join('|');
      this.visible = false;
      this.$emit('confirm', result, this.selectedIds);
      this.$emit('input', this.selectedIds);
    },
    show() {
      this.param.organId = this.organId;
      this.doRefresh();
    },
    organChange() {
      this.param.depIds = [];
      this.doRefresh();
    },
    doRefresh() {
      this.param.page = 1;
      this.getList();
    },
    getList() {
      // utils.showSpin();
      this.loading = true;
      request
        .get('/platform/reward/store/list', this.param)
        .then(ret => {
          // console.log(ret);
          this.data = ret.data;
          this.visible = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //设置每页的显示条数
    setLimit(current, limit) {
      this.param.page = 1;
      this.param.limit = limit;
      this.getList();
    },
    //页码切换
    getNext(page) {
      this.param.page = page;
      this.getList();
    }
  },
  mounted() {
    this.selectedIds = [];
    this.selectedList = [];
    this.selectNames = '';
    if (apiUtil.getOrganId() == 0) {
      this.keys = [
        { title: '物品名称', dataIndex: 'productName', align: 'left', width: 120, ellipsis: true },
        { title: '兑换所需积分', dataIndex: 'pointsRequired', align: 'left', width: 100, ellipsis: true },
        { title: '物品数量', dataIndex: 'stockQuantity', align: 'left', width: 100, ellipsis: true },
        { title: '所属单位', scopedSlots: { customRender: 'organId' }, width: 260, ellipsis: true }
      ];
    } else {
      this.keys = [
        { title: '物品名称', dataIndex: 'productName', align: 'left', width: 120, ellipsis: true },
        { title: '兑换所需积分', dataIndex: 'pointsRequired', align: 'left', width: 100, ellipsis: true },
        { title: '物品数量', dataIndex: 'stockQuantity', align: 'left', width: 100, ellipsis: true },
      ];
    }
  },
  computed: {
    rowSelection() {
      const { selectedIds } = this;
      return {
        selectedRowKeys: selectedIds,
        type: this.multiple ? 'checkbox' : 'radio',
        onChange: (ids, rows) => {
          this.selectedIds = ids;
          this.selectedList[this.param.page] = rows;
        }
      };
    }
  },
  watch: {
    value(val) {
      if (!utils.isEmpty(val)) {
        if (typeof val == 'string') {
          const ids_arr = val.split(',');
          const ids_num = [];
          ids_arr.forEach(id => {
            ids_num.push(parseInt(id));
          });
          this.selectedIds = ids_num;
        } else if (typeof val == 'number') {
          this.selectedIds = [val];
        } else {
          this.selectedIds = val;
        }
      } else {
        this.selectedIds = [];
      }
    },
    names(name) {
      if (!utils.isEmpty(name)) {
        if (typeof name == 'string') {
          this.selectNames = name;
        }
        if (typeof name == 'object') {
          this.selectNames = name.join('|');
        }
      } else {
        this.selectNames = '';
      }
    },
    organId(v) {
      this.param.organId = v;
    }
  },
  props: {
    //是否可多选
    multiple: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    //已选中的物品ID
    value: {
      type: Array | Number | String,
      default: () => {
        return [];
      }
    },
    //已选择物品姓名
    names: {
      type: Array | String,
      default: () => {
        return [];
      }
    },
    organId: {
      type: String | Number,
      default: () => {
        return apiUtil.getOrganId();
      }
    }
  }
};
</script>

<style></style>
